import './App.css';
import { useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import logo from './assets/logoGiftbox@3x.png';
import logoText from './assets/group28@3x.png';

function App() {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "https://download.cupio.app/redirect";
    }, 2000);
    /* if (navigator.userAgent.includes('iP') || navigator.userAgent.includes("Mac")) {
      //window.location.href = "https://download.cupio.app/redirect";
    } else {
      //window.location.href = "https://play.google.com/store/apps/details?id=com.cupio.cupio&fbclid=IwAR2WYsRsE7nPmbngxresCOTTBmzqscqBk-UFjwq1r1uJ4GuUQX4bm-VBL04";
    } */
  }, [])

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: "90vh",
      flexDirection: "column"
    }}>
      <div style={{ marginBottom: "2vh" }}>
        <img style={{ marginRight: "5vw", height: "40px" }} src={logo} />
        <img style={{ height: "40px" }} src={logoText} />
      </div>
      <div style={{ position: "absolute", marginTop: "30vh" }}>
        <Player
          style={{ height: "100px", width: "100px" }}
          src='https://assets9.lottiefiles.com/packages/lf20_bBm6D0w762.json'
          className="player"
          loop
          autoplay
        />
      </div>
    </div>
  );
}

export default App;
